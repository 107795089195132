* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

::selection {
  background: rgba(0, 0, 0, 0.15);
}

::-moz-selection {
  background: rgba(0, 0, 0, 0.15);
}
